import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var GetCentralizedOrganizationLogoDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetCentralizedOrganizationLogo"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "organizationId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "centralized_organization"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "organization_id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "organizationId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "personalization"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "logo"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useGetCentralizedOrganizationLogoQuery__
 *
 * To run a query within a React component, call `useGetCentralizedOrganizationLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCentralizedOrganizationLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCentralizedOrganizationLogoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetCentralizedOrganizationLogoQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(GetCentralizedOrganizationLogoDocument, options);
}
export function useGetCentralizedOrganizationLogoLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(GetCentralizedOrganizationLogoDocument, options);
}