import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useQuery } from "@apollo/client";
import { transformData } from "@crehana/utils";
var ACADEMIES_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "AcademiesQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "catalogCategories"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "educationAcademies"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "catalogCategories"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "catalogCategories"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalCount"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "buckets"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "name"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "quantity"
                },
                arguments: [],
                directives: []
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "edges"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "node"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "AcademyCardFragment"
                    },
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "AcademyCardFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EducationAcademyNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "slug"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isNew"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "buckets"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "url"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "absoluteUrl"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "relativeUrl"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "academyConfiguration"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "logo"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "url"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "placeholder"
                },
                arguments: [],
                directives: []
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalSkillPath"
            },
            arguments: [],
            directives: []
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 316,
    source: {
      body: "#import \"../Fragments/AcademyCardFragment.v4.graphql\"\n\nquery AcademiesQuery($catalogCategories: [String]) {\n  educationAcademies(catalogCategories: $catalogCategories) {\n    totalCount\n    buckets {\n      id\n      name\n      quantity\n    }\n    edges {\n      node {\n        ...AcademyCardFragment\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useAcademiesQuery = function useAcademiesQuery() {
  var _useQuery = useQuery(ACADEMIES_QUERY, {
      fetchPolicy: 'cache-first',
      context: {
        clientName: 'v4'
      }
    }),
    data = _useQuery.data,
    loading = _useQuery.loading;

  // As we are in strict mode we can't reorder the immutable variable data, so create a new variable
  // sortedBuckets to allow ordering.
  // @ts-expect-error  This kind of expression is always truthy.
  var sortedBuckets = _toConsumableArray((data === null || data === void 0 ? void 0 : data.educationAcademies.buckets) || []) || [];
  sortedBuckets = sortedBuckets.sort(function (a, b) {
    if (a !== null && a !== void 0 && a.id && b !== null && b !== void 0 && b.id) {
      return b.id - a.id;
    }
    return 0;
  });
  return {
    loading: loading,
    buckets: sortedBuckets,
    academies: data !== null && data !== void 0 && data.educationAcademies ? transformData(data.educationAcademies) : []
  };
};
export default useAcademiesQuery;