import notEmptyNode from "../notEmptyNode";
function transformData(data) {
  if (data) {
    if (!data.edges || data.edges.length === 0) return [];
    return data.edges.filter(notEmptyNode).map(function (_ref) {
      var node = _ref.node;
      return node;
    });
  }
  return [];
}
export default transformData;